import { collection, doc, getDoc } from 'firebase/firestore';
import { StorageError, getDownloadURL, ref } from 'firebase/storage';
import { firestore, storage } from '../firebase';
import { toAgeYears } from '../utils/strings';
import { getAuth, getIdToken } from 'firebase/auth';
import { DateTime } from 'luxon';

export enum ConciergeInterestRequestStatus {
	OPEN = 'OPEN',
	FOLLOW_UP_SENT = 'FOLLOW_UP_SENT',
	INTERESTED = 'INTERESTED',
	NOT_INTERESTED = 'NOT_INTERESTED',
	CLOSED = 'CLOSED',
	CLIENT_CONTACTED = 'CLIENT_CONTACTED',
	CLIENT_INTERESTED = 'CLIENT_INTERESTED',
	CLIENT_NOT_INTERESTED = 'CLIENT_NOT_INTERESTED',
	CONNECTED = 'CONNECTED',
}

export type FetchProfileResponseModel = {
	status: ConciergeInterestRequestStatus;
	profile?: {
		profileImages: string[];
		firstName: string;
		location: string;
		age: number;
		heightCm: number;
		profession: string;
		education: string;
		religion?: string;
		career: string;
		loves: string[];
		hates: string[];
		firstDateSuggestions: string[];
	};
};

type InterestResponseResponseModel = { success: boolean; error?: { message: string } };

export const requestProfile = async (
	token: string
): Promise<FetchProfileResponseModel | undefined> => {
	try {
		const url = `${process.env.REACT_APP_API_HOST}/matchmaker/interest/${token}`;
		const response = await fetch(url, { method: 'GET' });

		if (response.ok) {
			const data: FetchProfileResponseModel = await response.json();
			return data;
		}
	} catch (e) {
		console.log('failed to fetch profile', e);
	}
	console.warn('profile not found');
};

export const registerCandidateInterest = async (
	token: string,
	interested: boolean
): Promise<InterestResponseResponseModel> => {
	try {
		const url = `${process.env.REACT_APP_API_HOST}/matchmaker/interest/${token}/response`;
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
			body: JSON.stringify({
				response: interested
					? ConciergeInterestRequestStatus.INTERESTED
					: ConciergeInterestRequestStatus.NOT_INTERESTED,
			}),
		});
		if (response.ok || response.status === 400) {
			return await response.json();
		}
	} catch (e) {
		console.log('failed to register interest', e);
	}
	return { success: false };
};

export const registerClientInterest = async (
	token: string,
	interested: boolean
): Promise<InterestResponseResponseModel> => {
	try {
		const currentUser = getAuth().currentUser;
		if (!currentUser) {
			return { success: false };
		}
		const authToken = await getIdToken(currentUser);
		const url = `${process.env.REACT_APP_API_HOST}/matchmaker/interest/${token}/response`;
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
				Authorization: `Bearer ${authToken}`,
			},
			body: JSON.stringify({
				response: interested
					? ConciergeInterestRequestStatus.CLIENT_INTERESTED
					: ConciergeInterestRequestStatus.CLIENT_NOT_INTERESTED,
			}),
		});
		if (response.ok || response.status === 400) {
			return await response.json();
		}
	} catch (e) {
		console.log('failed to register interest', e);
	}
	return { success: false };
};

const _getOptimisedPicUrl = async (picturePath: string) => {
	let optimisedPath;
	if (picturePath.includes('optimised')) {
		optimisedPath = picturePath;
	} else {
		const fileNameExpression = /([\w-]+).jpg/;
		const match = picturePath.match(fileNameExpression);
		const fileName = match?.[match.length - 1] as string;
		optimisedPath = picturePath.replace(fileName, `optimised/${fileName}_1280x1920`);
	}

	try {
		const optimisedUrl = await getDownloadURL(ref(storage, optimisedPath));
		return optimisedUrl;
	} catch (e) {
		if (e instanceof StorageError) {
			if (e.code === 'storage/object-not-found') {
				try {
					const basicUrl = await getDownloadURL(ref(storage, picturePath));
					return basicUrl;
				} catch (e) {
					console.warn('Failed to get download url for unoptimised path.', e);
				}
			}
		}
		return '';
	}
};

export const getProfileForClient = async (
	token: string
): Promise<FetchProfileResponseModel | undefined> => {
	try {
		const interestRequestDoc = await getDoc(
			doc(collection(firestore, 'conciergeinterestrequests'), token)
		);
		if (!interestRequestDoc.exists()) {
			return undefined;
		}
		const interestRequest = interestRequestDoc.data();
		if (
			![
				ConciergeInterestRequestStatus.CLIENT_CONTACTED,
				ConciergeInterestRequestStatus.CLIENT_INTERESTED,
			].includes(interestRequest.status)
		) {
			return { status: interestRequest.status };
		}
		const viewExpiresAt = interestRequest.viewCandidateProfileExpiresAt;
		if (viewExpiresAt && DateTime.now().toMillis() > viewExpiresAt.toMillis()) {
			return { status: interestRequest.status };
		}
		const profileId = interestRequest.candidateUserId;
		const profileDoc = await getDoc(doc(collection(firestore, 'profiles'), profileId));
		if (!profileDoc.exists()) {
			return undefined;
		}
		const profile = profileDoc.data();
		const profileImageUrls = await Promise.all([
			_getOptimisedPicUrl(profile.profilePicture_0),
			_getOptimisedPicUrl(profile.profilePicture_1),
			_getOptimisedPicUrl(profile.profilePicture_2),
		]);
		return {
			status: interestRequest.status,
			profile: {
				profileImages: profileImageUrls,
				firstName: profile.firstName,
				location: profile.currentLocation.name,
				age: toAgeYears(profile.birthday),
				heightCm: profile.height,
				profession: profile.profession,
				education: profile.education,
				religion: profile.religion,
				career: profile.careerHistory,
				loves: profile.loves ?? [],
				hates: profile.hates ?? [],
				firstDateSuggestions: profile.firstDateSuggestions ?? [],
			},
		};
	} catch (e) {
		return undefined;
	}
};
