import { useCallback, useEffect, useState } from 'react';
import { ProfileComponentParams } from '../types';
import {
	ConciergeInterestRequestStatus,
	registerCandidateInterest,
	requestProfile,
} from '../../../api/profile';

const useCandidateRequestViewModel = ({ token }: { token: string }): ProfileComponentParams => {
	const [loading, setLoading] = useState(false);
	const [requestStatus, setRequestStatus] = useState<ConciergeInterestRequestStatus>();
	const [profileData, setProfileData] = useState<ProfileComponentParams['data']>();
	const [reloadData, setReloadData] = useState(true);

	const fetchProfile = useCallback(async () => {
		setLoading(true);
		const response = await requestProfile(token);
		if (response) {
			setProfileData(response.profile);
			setRequestStatus(response.status);
		}
		setLoading(false);
	}, [setLoading, token]);

	useEffect(() => {
		if (reloadData) {
			fetchProfile();
			setReloadData(false);
		}
	}, [reloadData, fetchProfile]);

	const registerInterestInternal = useCallback(
		async (interested: boolean) => {
			await registerCandidateInterest(token, interested);
			setReloadData(true);
		},
		[token]
	);

	const params: ProfileComponentParams = {
		loading,
		data: profileData,
		requestStatus,
		registerInterest: registerInterestInternal,
	};

	return params;
};

export default useCandidateRequestViewModel;
