import useCandidateRequestViewModel from './model';
import type { FC } from 'react';
import { ConciergeInterestRequestStatus } from '../../../api/profile';
import LoadingView from '../LoadingView';
import ClosedView from '../ClosedView';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import ProfileView from '../ProfileView';
import InvalidUrlView from '../InvalidUrlView';

const _candidateHasResponded = (requestStatus: ConciergeInterestRequestStatus): boolean => {
	switch (requestStatus) {
		case ConciergeInterestRequestStatus.OPEN:
		case ConciergeInterestRequestStatus.FOLLOW_UP_SENT:
			return false;
		case ConciergeInterestRequestStatus.INTERESTED:
		case ConciergeInterestRequestStatus.NOT_INTERESTED:
		case ConciergeInterestRequestStatus.CLIENT_CONTACTED:
		case ConciergeInterestRequestStatus.CLIENT_INTERESTED:
		case ConciergeInterestRequestStatus.CLIENT_NOT_INTERESTED:
		case ConciergeInterestRequestStatus.CONNECTED:
		case ConciergeInterestRequestStatus.CLOSED:
			return true;
	}
};

const CandidateRequestView: FC<{ token: string }> = ({ token }) => {
	const { t } = useTranslation();
	const { loading, requestStatus, data, registerInterest } = useCandidateRequestViewModel({
		token,
	});

	if (loading) {
		return <LoadingView />;
	}

	if (requestStatus === ConciergeInterestRequestStatus.CLOSED) {
		return <ClosedView />;
	}

	if (requestStatus && _candidateHasResponded(requestStatus)) {
		return (
			<>
				<Helmet>
					<title>{`${t('matchmaking_name')} - Response Recorded`}</title>
				</Helmet>
				<Row>
					<Col className="text-center p-5">
						Thanks for getting back to us.
						<br />
						<br />
						{requestStatus === ConciergeInterestRequestStatus.NOT_INTERESTED ? (
							'Your response has been recorded.'
						) : (
							<>
								We’ll let {data?.firstName ?? 'them'} know and circle back if it’s mutual.
								<br />
								<br />
								<span style={{ fontSize: 14, color: '#b9b8b8' }}>
									If you have any questions, feel free to email {t('matchmakerEmail')}.
								</span>
							</>
						)}
					</Col>
				</Row>
				{data && (
					<Row>
						<Col md={{ span: 6, offset: 3 }} xs={{ span: 10, offset: 1 }}>
							<ProfileView data={data} />
						</Col>
					</Row>
				)}
			</>
		);
	}

	if (!data) {
		return <InvalidUrlView />;
	}

	return (
		<>
			<Helmet>
				<title>{`${t('matchmaking_name')} - ${data.firstName}`}</title>
			</Helmet>
			<Row className="pt-5">
				<Col md={{ span: 6, offset: 3 }} xs={12} className="px-4 px-md-0">
					<ProfileView data={data} registerInterest={registerInterest} />
				</Col>
			</Row>
		</>
	);
};

export default CandidateRequestView;
